import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function OrdersList({
  orders,
  handleNextPage,
  handlePrevPage,
  page,
  totalPages,
  setRetoure,
}: any) {
  return (
    <div className="bg-white p-4 rounded-lg">
      <table className="table-auto w-full mb-5">
        <thead>
          <tr className="text-left">
            <th className="px-4 py-2">Order Info</th>
            <th className="px-4 py-2">Items</th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 &&
            orders.map((order: any) => (
              <tr key={order.id} className="text-left">
                <td className="border px-2 py-2">
                  <b>Order number: </b>
                  {order.order_number}
                  <br></br>
                  <b>Customer: </b>
                  {order.customer_first_name} {order.customer_last_name}
                  <br></br>
                  <b>Location: </b>
                  {order.billing_city} {order.billing_zip}{" "}
                  {order.billing_country_code}
                  <br></br>
                </td>
                <td className="border px-4 py-2">
                  <div className="px-2 py-2">
                    {order.line_items.map((item: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="text-sm px-2 py-1 border-b-2 border-gray-200 md:flex justify-between items-center"
                        >
                          <div>
                            <b>Name:</b> {item.name}, <b>Price:</b> {item.price}
                            €, <b>SKU:</b> {item.sku}
                            <br></br>
                            <b>Product ID:</b> {item.product_id}
                            <br></br>
                            <b>Variant ID:</b> {item.variant_id}
                            <br></br>
                          </div>
                          <RetoureButton
                            retoureState={item.retoure}
                            itemId={item.id}
                            setRetoure={setRetoure}
                          />
                        </div>
                      );
                    })}
                  </div>
                </td>
              </tr>
            ))}
          {orders.length === 0 && (
            <tr>
              <td colSpan={2} className="border px-4 py-2">
                No orders found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {orders.length > 0 &&
        handleNextPage &&
        handlePrevPage &&
        page &&
        totalPages && (
          <div className="flex justify-between">
            {page > 1 && page <= totalPages && (
              <button
                onClick={handlePrevPage}
                className="bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-lg hover:bg-gray-400"
              >
                Previous Page
              </button>
            )}
            <div></div>
            {page < totalPages && (
              <button
                onClick={handleNextPage}
                className="bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-lg hover:bg-gray-400"
              >
                Next Page
              </button>
            )}
          </div>
        )}
    </div>
  );
}

const RetoureButton = ({ retoureState, itemId, setRetoure }: any) => {
  const [bgColor, setBgColor] = useState(
    retoureState ? "bg-green-200" : "bg-red-200",
  );
  const [text, setText] = useState(retoureState ? "No Retoure" : "Retoure");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disabled) {
      setBgColor("bg-gray-200");
      setText("Updating...");
      return;
    }

    if (retoureState) {
      setBgColor("bg-red-200");
      setText("Retoure");
    } else {
      setBgColor("bg-green-200");
      setText("No Retoure");
    }
  });

  const onClick = () => {
    setRetoure(!retoureState, itemId);
    setDisabled(true);

    setTimeout(() => {
      setDisabled(false);
    }, 2000);
  };

  return (
    <button
      className={`${bgColor} px-2 rounded-lg h-12`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default OrdersList;
