import React from 'react';
import Header from './header';

export const Layout: React.FC<any> = ({ children }) => {
    return (
        <div>
            <Header />
            <div className="pt-5">{children}</div>
        </div>
    );
};