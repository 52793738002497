import * as React from "react"
import type {HeadFC, PageProps} from "gatsby";
import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from "react";
import {Layout} from "../components/layout";
import OrdersList from "../components/ordersList";
import Loading from "../components/loading";

const SearchPage: React.FC<PageProps> = () => {
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');

    const searchCall = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently({
                audience: process.env.GATSBY_AUTH0_AUDIENCE
            });

            const url = new URL('https://mutterkleid-inventory.netlify.app/.netlify/functions/search-rent-order-items');
            url.searchParams.append('search', search);

            const response = await fetch(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + token
                },
            });

            const body = await response.json();
            setIsLoading(false);
            if (response.status === 200 && body?.rows.length > 0) {
                const orders = body.rows.map((order: any) => {
                    return {
                        ...order.order_arr[0]
                    }
                });
                setOrders(orders);
            } else {
                console.log(response.statusText);
            }
        } catch (e: any) {
            setIsLoading(false);
            return e.message;
        }
    };

    const setRetoure = async (retoure: boolean, itemId: number) => {
        const token = await getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE
        });

        const url = new URL('https://mutterkleid-inventory.netlify.app/.netlify/functions/retour-handler');

        try {
            const res = await fetch(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + token
                },
                method: 'POST',
                body: JSON.stringify({
                    itemId: itemId,
                    state: retoure,
                })
            });
            if (res.status === 200) {
                orders.forEach((order: any) => {
                    order.line_items.forEach((item: any) => {
                        if (item.id === itemId) {
                            item.retoure = retoure;
                        }
                    });
                });
                setOrders([...orders]);
            }
        } catch (e: any) {
            console.log(e.message);
        }
    }

    return (
        <Layout>
            <div className="container mx-auto">
                {isAuthenticated ? (
                    <div>
                        <h2 className="text-xl pl-5 mb-5">Search for orders</h2>
                        <div className="flex items-center">
                            <input
                                className="px-3 py-2 rounded-lg w-full border-2 mx-4"
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        searchCall();
                                    }
                                }}
                            />
                            <button
                                className="px-3 py-2 mr-5 rounded-lg bg-blue-500 text-white"
                                onClick={searchCall}
                            >
                                Search
                            </button>
                        </div>
                        {isLoading ? (
                            <Loading/>
                        ) : (
                            <OrdersList orders={orders} setRetoure={setRetoure}/>
                        )}
                    </div>) : (
                    <div className="text-center mt-5">Please log in to view orders.</div>
                )}
            </div>
        </Layout>
    );
};


export default SearchPage

export const Head: HeadFC = () => <title>MK Routernmanager</title>
