import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Layout } from "../components/layout";
import OrdersList from "../components/ordersList";
import Loading from "../components/loading";

const IndexPage: React.FC<PageProps> = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const getOrders = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently({
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
      });

      const url = new URL(
        "https://mutterkleid-inventory.netlify.app/.netlify/functions/get-rent-order-items",
      );
      url.searchParams.append("page", page.toString());

      const api = await fetch(url.toString(), {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const body = await api.json();
      setIsLoading(false);
      return {
        status: api.status,
        statusText: api.statusText,
        body,
      };
    } catch (e: any) {
      setIsLoading(false);
      return e.message;
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1 && page <= totalPages) {
      setPage(page - 1);
    }
  };

  const setRetoure = async (retoure: boolean, itemId: number) => {
    const token = await getAccessTokenSilently({
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
    });

    const url = new URL(
      "https://mutterkleid-inventory.netlify.app/.netlify/functions/retour-handler",
    );

    try {
      const res = await fetch(url.toString(), {
        headers: {
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify({
          itemId: itemId,
          state: retoure,
        }),
      });
      if (res.status === 200) {
        orders.forEach((order: any) => {
          order.line_items.forEach((item: any) => {
            if (item.id === itemId) {
              item.retoure = retoure;
            }
          });
        });
        setOrders([...orders]);
      }
    } catch (e: any) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getOrders().then((response) => {
        if (response.status === 200 && response.body?.rows.length > 0) {
          const orders = response.body.rows.map((order: any) => {
            return {
              ...order.order_arr[0],
            };
          });
          setOrders(orders);
          setTotalPages(response.body.totalPages);
        } else {
          console.log(response.statusText);
        }
      });
    }
  }, [isAuthenticated, page]);

  return (
    <Layout>
      <div className="container mx-auto">
        {isAuthenticated ? (
          <div>
            <h2 className="text-xl pl-5 mb-2">Rent orders & line items</h2>
            {isLoading ? (
              <Loading />
            ) : (
              <OrdersList
                orders={orders}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                page={page}
                totalPages={totalPages}
                setRetoure={setRetoure}
              />
            )}
          </div>
        ) : (
          <div className="text-center mt-5">Please log in to view orders.</div>
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>mutterkleid RM</title>;
